import axios from "axios";
import { domain } from "./constants";

const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};

// const headers = {
//   "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
//   "Content-Type": "application/json",
//   "Access-Control-Allow-origin": "*",
// };

export const registerUser = (credentials, cb) => {
  axios
    .post(`${domain}/account/register`, { ...credentials })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const loginUser = (credentials, cb) => {
  axios
    .post(`${domain}/account/login`, credentials)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const getUserDetails = (cb) => {
  const header = getAuthHeaders();
  axios
    .get(`${domain}/account/profile`, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const refreshAcessToken = (refresh, cb) => {
  axios
    .post(`${domain}/account/login/refresh`, { refresh }, { "Content-Type": "application/json" })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

//sends otp to email
export const verifyEmail = (email, cb) => {
  axios
    .post(`${domain}/account/send-email-verification-otp`, { email })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

//verifies otp
export const verifyEmailCode = (data, cb) => {
  const credentials = { email: data.email, otp: data.code };
  axios
    .post(`${domain}/account/verify-otp`, credentials)
    .then((res) => cb(null, res.data))
    .catch((err) => cb(err, null));
};

export const requestPasswordResetEmail = (credentials, cb) => {
  axios
    .post(`${domain}/account/send-password-reset-otp`, credentials)
    .then((res) => cb(null, res.data))
    .catch((err) => cb(err, null));
};

export const updateUserCredentials = (credentials, cb) => {
  axios
    .post(`${domain}/account/reset-password`, credentials)
    .then((res) => cb(null, res.data))
    .catch((err) => cb(err, null));
};

export const refreshUserToken = (credentials, cb) => {
  axios
    .post(`${domain}/account/login/refresh`, credentials)
    .then((res) => cb(null, res.data))
    .catch((err) => cb(err, null));
};

export const refreshGoogleToken = (credentials) => {
 return axios.post(`${domain}/social-auth/token`, credentials) 
};
