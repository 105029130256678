import React, { useState } from 'react';
import './OnBoarding.css';
import { saveOnboardingAsync } from '../../redux/slices/OnboardingReducer';
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import SelectedImg from "../../assets/icons/selected.svg";
import Select from 'react-select';
// import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";

const OnBoarding = () => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [useType, setuseType] = useState("");
    const [clickContinue, setclickContinue] = useState(false);
    const [invitePage, setinvitePage] = useState(false);
    const [work, setwork] = useState('');
    const [role, setrole] = useState('');
    const [companySize, setcompanySize] = useState('');
    const [planInDuonut, setplanInDuonut] = useState([]);
    const [planInDuonutValues, setplanInDuonutValues] = useState([]);
    const [email1, setemail1] = useState('');
    const [email2, setemail2] = useState('');
    const [email3, setemail3] = useState('');
    const planInDuonutOptions = [
        { value: 'Games - spinwheel, jigsaw puzzles etc', label: 'Games - spinwheel, jigsaw puzzles etc' },
        { value: 'Different formats of quizzes', label: 'Different formats of quizzes' },
        { value: 'Landing pages', label: 'Landing pages' },
        { value: 'Linker Tree', label: 'Linker Tree' },
        { value: 'Others', label: 'Others' }
    ]

    const selectBtn = (item) => {
        // console.log(item);
        setuseType(item);
    }
    const continueBtn = () => {
        // console.log("continueBtn");
        setclickContinue(true);
    }
    const gotoInvite = () => {
        // console.log("gotoInvite");
        setclickContinue(false);
        setinvitePage(true);
    }

    const handleWorkChange = event => {
        setwork(event.target.value);
        // console.log(event.target.value);
    };

    const handleRoleChange = event => {
        setrole(event.target.value);
        // console.log(event.target.value);
    };

    const handleCompanySizeChange = event => {
        setcompanySize(event.target.value);
        // console.log(event.target.value);
    };

    const handlePlanInDuonutChange = planInDuonutValues => {
        // console.log(planInDuonutValues);
        setplanInDuonutValues(planInDuonutValues);

        var planInDuonutArray = planInDuonutValues.map(item => item.value)
        // console.log(planInDuonutArray);

        setplanInDuonut(planInDuonutArray);
    };

    const handleEmail1Change = event => {
        setemail1(event.target.value);
        // console.log(event.target.value);
    }; const handleEmail2Change = event => {
        setemail2(event.target.value);
        // console.log(event.target.value);
    }; const handleEmail3Change = event => {
        setemail3(event.target.value);
        // console.log(event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        var referalEmail = [];
        if (email1) {
            referalEmail.push(email1)
        }
        if (email2) {
            referalEmail.push(email2)
        }
        if (email3) {
            referalEmail.push(email3)
        }

        var onboarding_data = {
            use_type: useType,
            work: work,
            role: role,
            company_size: companySize,
            plans_in_duonut: planInDuonut,
            reference_email: referalEmail
        }
        // console.log(onboarding_data)
        dispatch(saveOnboardingAsync(onboarding_data));

        setTimeout(() => {
            // if (work === "Educator" || work === "Student") {
            //     navigate('/templates/education_&_learning');
            // } else if (work === "Marketing" || work === "Sales" || work === "Finance") {
            //     navigate('/templates/sales_&_marketing');
            // } else {
            //     navigate('/templates');
            // }
            // navigate('/createworkflow');
            window.location = "/home";
            // dispatch(toggleCreateFromScratchModalDuonut(true));

            // window.location.reload();
        }, 100); //miliseconds

    }

    const skip = () => {
        var referalEmail = [];

        var onboarding_data = {
            use_type: useType,
            work: work,
            role: role,
            company_size: companySize,
            plans_in_duonut: planInDuonut,
            reference_email: referalEmail
        }
        // console.log(onboarding_data)
        dispatch(saveOnboardingAsync(onboarding_data));

        setTimeout(() => {
            // navigate('/templates');
            // window.location.reload();
            // navigate('/createworkflow');
            window.location = "/home";
            // dispatch(toggleCreateFromScratchModalDuonut(true));
        }, 100); //miliseconds

    }

    return (
        <div className='OB1App'>
            {(!clickContinue && !invitePage) && <div className='OB1useTypeTemplate'>
                <h1>How are you planning to use Duonut?</h1>
                <h4>We'll streamline your setup experience accordingly.</h4>
                <div className="OB1container">
                    <div className='OB1useType' onClick={() => selectBtn("Team")}>
                        {useType === "Team" ? <img className="OB1Icon" src={SelectedImg} />
                            : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                        <img src="/assets/team.png" className="OB1Image" />
                        <h3>For my team</h3>
                        {/* <div>Collaborate on your docs. </div><div>projects and wikis.</div> */}
                    </div>
                    <div className='OB1useType' onClick={() => selectBtn("Personal")}>
                        {useType === "Personal" ? <img className="OB1Icon" src={SelectedImg} />
                            : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                        <img src="/assets/personal.png" className="OB1Image" />
                        <h3>For personal use</h3>
                        {/* <div>Write better. Think more. </div><div>clearly. Stay organized</div> */}
                    </div>
                    <div className='OB1useType' onClick={() => selectBtn("School")}>
                        {useType === "School" ? <img className="OB1Icon" src={SelectedImg} />
                            : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                        <img src="/assets/school.png" className="OB1Image" />
                        <h3>For school</h3>
                        {/* <div>Keep your notes, research and </div><div>tasks all in one place.</div> */}
                    </div>
                </div>
                <button className='OB1button' disabled={useType ? false : true} onClick={() => continueBtn()}>Continue</button>
                <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div>
            </div>}

            {(useType === "Team" && clickContinue) && <div className='OB1useTypeTemplate'>
                <h1>Tell us about yourself</h1>
                <h4>We'll customize your Duonut experience based on your choice.</h4>
                <div className="OB1TeamContainer">
                    <form id="demoForm" onSubmit={gotoInvite}>
                        <div className='OB1input_container'>
                            <label>What kind of work do you do?</label>
                            <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Engineering">Engineering</option>
                                <option value="IT">IT</option>
                                <option value="Design">Design</option>
                                <option value="Human Resources">Human Resources</option>
                                <option value="Sales">Sales</option>
                                <option value="Finance">Finance</option>
                                <option value="Operations">Operations</option>
                                <option value="Educator">Educator</option>
                                <option value="Product Management">Product Management</option>
                                <option value="Student">Student</option>
                                <option value="Customer Service">Customer Service</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What is your role?</label>
                            <select id="role" required className='OB1input_container_select' value={role} onChange={e => handleRoleChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Executive">Executive</option>
                                <option value="Department Lead">Department Lead</option>
                                <option value="Team Manager">Team Manager</option>
                                <option value="Team Member">Team Member</option>
                                <option value="Solo-preneur / Freelancer">Solo-preneur / Freelancer</option>
                                <option value="Using Duonut just for myself">Using Duonut just for myself</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What is the size of your company?</label>
                            <select id="companySize" required className='OB1input_container_select' value={companySize} onChange={e => handleCompanySizeChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="1-49">1-49</option>
                                <option value="50-99">50-99</option>
                                <option value="100-299">100-299</option>
                                <option value="00-999">300-999</option>
                                <option value="1000-5000">1000-5000</option>
                                <option value="5000+">5000+</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What are you planning to do in Duonut?</label>
                            <Select
                                className='OB1input_container_select'
                                isMulti
                                options={planInDuonutOptions}
                                value={planInDuonutValues}
                                onChange={handlePlanInDuonutChange}
                            />
                        </div>

                        <button type='submit' className='OB1button'>Continue</button>
                    </form>
                    <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div>
                </div>
            </div>}
            {(invitePage && !clickContinue) && <div className='OB1useTypeTemplate'>
                <h1>Invite Teammates</h1>
                <h4>Get the most out of Duonut by inviting your teammates.</h4>
                <div className="OB1TeamContainer">
                    <form id="demoForm" onSubmit={submit}>
                        <div className='OB1input_container'>
                            <div>Send Invites</div>
                            <input className='OB1input_container_select'
                                placeholder="Email address"
                                value={email1}
                                onChange={e => handleEmail1Change(e)}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                required
                            />
                        </div>
                        <div className='OB1input_container'>
                            <input className='OB1input_container_select'
                                placeholder="Email address"
                                value={email2}
                                onChange={e => handleEmail2Change(e)}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            />
                        </div>
                        <div className='OB1input_container'>
                            <input className='OB1input_container_select'
                                placeholder="Email address"
                                value={email3}
                                onChange={e => handleEmail3Change(e)}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            />
                        </div>

                        <button type='submit' className='OB1button'>Send Invite</button>
                    </form>
                    <div style={{ cursor: "pointer", margin: "1vh auto" }} onClick={(e) => submit(e)}>Skip</div>

                </div>
            </div>}

            {(useType === "Personal" && clickContinue) && <div className='OB1useTypeTemplate'>
                <h1>Tell us about yourself</h1>
                <h4>We'll customize your Duonut experience based on your choice.</h4>
                <div className="OB1TeamContainer" style={{ paddingTop: "2vh" }}>
                    <form id="demoForm" onSubmit={submit}>
                        <div className='OB1input_container'>
                            <label>What kind of work do you do?</label>
                            <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Engineering">Engineering</option>
                                <option value="IT">IT</option>
                                <option value="Design">Design</option>
                                <option value="Human Resources">Human Resources</option>
                                <option value="Sales">Sales</option>
                                <option value="Finance">Finance</option>
                                <option value="Operations">Operations</option>
                                <option value="Educator">Educator</option>
                                <option value="Product Management">Product Management</option>
                                <option value="Student">Student</option>
                                <option value="Customer Service">Customer Service</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What is your role?</label>
                            <select id="role" required className='OB1input_container_select' value={role} onChange={e => handleRoleChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Executive">Executive</option>
                                <option value="Department Lead">Department Lead</option>
                                <option value="Team Manager">Team Manager</option>
                                <option value="Team Member">Team Member</option>
                                <option value="Solo-preneur / Freelancer">Solo-preneur / Freelancer</option>
                                <option value="Using Duonut just for myself">Using Duonut just for myself</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What are you planning to do in Duonut?</label>
                            <Select
                                className='OB1input_container_select'
                                isMulti
                                options={planInDuonutOptions}
                                value={planInDuonutValues}
                                onChange={handlePlanInDuonutChange}
                            />
                        </div>

                        <button type='submit' className='OB1button'>Continue</button>
                    </form>
                    <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div>
                </div>
            </div>}

            {(useType === "School" && clickContinue) && <div className='OB1useTypeTemplate'>
                <h1>Tell us about yourself</h1>
                <h4>We'll customize your Duonut experience based on your choice.</h4>
                <div className="OB1TeamContainer" style={{ paddingTop: "2vh" }}>
                    <form id="demoForm" onSubmit={submit}>
                        <div className='OB1input_container'>
                            <label>What kind of work do you do?</label>
                            <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Student">Student</option>
                                <option value="Educator">Educator</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='OB1input_container'>
                            <label>What are you planning to do in Duonut?</label>
                            <Select
                                className='OB1input_container_select'
                                isMulti
                                options={planInDuonutOptions}
                                value={planInDuonutValues}
                                onChange={handlePlanInDuonutChange}
                            />
                        </div>

                        <button type='submit' className='OB1button'>Continue</button>
                    </form>
                    <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div>
                </div>
            </div>}

        </div>
    );

}

export default OnBoarding;